export default {
  views: {
    list: {
      header: {
        title: "Podjetja",
        search_placeholder: "Iskanje ...",
        add_button: "Novo podjetje"
      },
      table: {
        title: "Naziv",
        address: "Naslov",
        responsible: "Skrbnik",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktivno",
        options: "Možnosti",
        category: "Dejavnost (kategorija)",
        subcategory: "Dejavnost (podkategorija)",
        headhunting: "Headhunting"
      }
    },
    details: {
      header: {
        back_button: "Na listo podjetij",
        edit_button: "Uredi podjetje",
        toggle: {
          title: "Aktivno",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Podjetje lahko {action} tukaj"
        }
      },
      basic: {
        responsible: "Skrbnik",
        comment: "Opomba",
        tax_code: "Davčna številka",
        category: "Dejavnost (kategorija)",
        subcategory: "Dejavnost (podkategorija)",
        email: "Elektronski naslov",
        headhunting: "Headhunting"
      },
      projects: {
        headline: "Projekti",
        headline_description: "Vsi projekti podjetja v kronološkem zaporedju. Podjetje še nima projektov.",
        table: {
          title: "Naziv projekta",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      contacts: {
        edit_button: "Uredi kontakte",
        contacts_popup: {
          save_button: "Shrani"
        }
      },
      users: {
        title: "Uporabniki",
        add_button: "Dodaj uporabnika"
      },
      employees: {
        title: "Zaposleni v podjetju",
        no_employees: "Podjetje nima nobenega zaposlenega.",
        add_button: "Nov zaposleni",
        hr_manager: "HR manager",
        prompts: {
          delete: {
            title: "Izbris zaposlenega {name}",
            message: "Ste prepričani da želite izbrisati zaposlenega {name}?",
            cancel: "Prekliči",
            confirm: "Izbriši"
          }
        }
      }
    }
  },
  popup: {
    title: "Novo podjetje",
    fields: {
      title: {
        label: "Naziv podjetja",
        placeholder: "Vpišite naziv podjetja"
      },
      address: {
        label: "Hišni naslov",
        placeholder: "Vpišite naslov in hišno številko"
      },
      postal: {
        label: "Poštni naslov",
        placeholder: "Vpišite poštno številko in kraj"
      },
      country: {
        label: "Država",
        placeholder: "Vpišite državo"
      },
      email: {
        label: "Elektronski naslov za pošiljanje opominov",
        placeholder: "Vpišite elektronski naslov za pošiljanje opominov"
      },
      category: {
        label: "Dejavnost (kategorija)"
      },
      subcategory: {
        label: "Dejavnost (podkategorija)"
      },
      comment: {
        label: "Opomba"
      },
      tax_code: {
        label: "Davčna številka",
        placeholder: "Vpišite davčno številko"
      },
      headhunting: {
        label: "Headhunting"
      },
      responsible: {
        label: "Skrbnik",
        not_found: "Oseba ne obstaja"
      },
      oppis_id: {
        label: "ID podjetja v OpPISu",
        placeholder: "Vpišite ID podjetja v OpPISu"
      },
      allowMultiple: {
        label: "Želite vpisati še eno?",
        description: "Po potrditvi bo forma ostala odprta"
      }
    },
    add_button: "Shrani podjetje",
    update_button: "Shrani podjetje"
  },
  prompts: {
    delete: {
      title: "Izbris podjetja {title}",
      message: "Ste prepričani da želite izbrisati podjetje {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili podjetje {title}",
    updated: "Uspešno ste posodobili podjetje {title}",
    deleted: "Uspešno ste izbrisali podjetje {title}",
    not_found: "Podjetje ni bilo najdeno"
  }
}
