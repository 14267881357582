export default {
  views: {
    list: {
      nps_avg: "NPS povprečje:",
      buttons: {
        export_excel: "Izvoz rezultatov"
      },
      header: {
        title: "Povratne informacije",
        search_placeholder: "Iskanje ...",
        add_button: "Nov ROSL"
      },
      table: {
        project: "Projekt",
        responsible: "Odgovorna oseba",
        company: "Podjetje",
        industry: "Industrija",
        status: "Status",
        finished_at: "Datum zaključka",
        questionnaire: "Vprašalnik",
        name: "Oseba",
        created_at: "Ustvarjen",
        nps: "NPS",
        email: "Elektronski naslov",
        project_title: "Naziv projekta",
        recipient: "Prejemnik",
        service: "Tip storitve"
      },
      status: {
        open: "Ni poslan",
        sent: "Poslan",
        closed: "Zaključen"
      },
      button: {
        send: "Pošlji",
        remove: "Odstrani"
      }
    }
  },
  popup: {
    title: "Nov ROSL",
    fields: {
      questionnaire: {
        label: "Vprašalnik",
        search: {
          not_found: "Vprašalnik ne obstaja"
        }
      },
      name: {
        label: "Ime prejemnika",
        placeholder: "Vpišite ime prejemnika"
      },
      email: {
        label: "Elektronski naslov za pošiljanje",
        placeholder: "Vpišite elektronski naslov za pošiljanje"
      },
      company: {
        label: "Podjetje",
        search: {
          not_found: "Podjetje ne obstaja"
        }
      },
      project_title: {
        label: "Naziv projekta",
        placeholder: "Vpišite naziv projekta"
      },
      responsible: {
        label: "Odgovorna oseba",
        search: {
          not_found: "Oseba ne obstaja"
        }
      },
      finished: {
        label: "Datum zaključka"
      },
      service: {
        label: "Tip storitve"
      }
    },
    add_button: "Shrani ROSL"
  },
  notification_select_popup: {
    title: "Pošlji mail in vprašalnik",
    label: "Predloga obvestil",
    placeholder: "Izberite predlogo obvestil",
    doesnt_exist: "Predloga obvestil ne obstaja"
  },
  notification_preview_popup: {
    title: "Pošlji mail in vprašalnik",
    recipient: "Prejemnik",
    subject: "Zadeva",
    buttons: {
      confirm: "Pošlji",
      cancel: "Prekliči"
    }
  },
  results_drawer: {
    title: "Rezultati",
    project: "Projekt",
    unit: "Oddelek",
    employees: "Št. zaposlenih",
    closed: "Opravljeno",
    open: "Neopravljeno",
    toggle: {
      answers: "Odgovori",
      show_answers: "Prikaži odgovore"
    },
    person: "Oseba",
    duration: "Čas reševanja",
    status: "Stanje",
    average: "M",
    sd: "SD",
    tag: {
      closed: "Opravljeno",
      open: "Neopravljeno"
    },
    total: "Skupaj",
    buttons: {
      export_excel: "Izvoz rezultatov"
    }
  },
  prompts: {
    delete: {
      title: "Izbris povratne informacije",
      message: "Ste prepričani da želite izbrisati povratno informacijo?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili povratno informacijo",
    updated: "Uspešno ste posodobili povratno informacijo",
    deleted: "Uspešno ste izbrisali povratno informacijo",
    not_found: "Povratna informacija ni bila najdena"
  }
}
