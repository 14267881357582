export default {
  views: {
    list: {
      header: {
        title: "Ponudbe",
        search_placeholder: "Iskanje ...",
        add_button: "Nova ponudba"
      },
      table: {
        title: "Naziv",
        status: "Status",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        responsible: "Odgovorna oseba",
        active: "Aktivna",
        options: "Možnosti",
        type: "Področje",
        headhunting: "Headhunting"
      }
    },
    edit: {
      title: "Nova ponudba",
      back_button: "Na listo ponudb",
      create_contract: {
        button: "Ustvari pogodbo",
        prompt: {
          title: "Ustvari pogodbo",
          message: "Ste prepričani, da želite iz ponudbe ustvariti pogodbo?",
          confirm: "Potrdi",
          cancel: "Prekliči"
        }
      },
      tabs: {
        overview: "Pregled",
        contacts: "Kontakti"
      },
      footer: {
        add_button: "Dodaj ponudbo",
        save_button: "Shrani"
      },
      overview: {
        title: "Naziv",
        company: "Podjetje",
        work_field: "Delovno mesto",
        service: "Storitev",
        candidates_count: "Število kandidatov",
        warranty: "Garancijsko obdobje (št. mesecev)",
        payment_type: "Način obračunavanja honorarja",
        payment_deadline: "Rok plačila (št. dni)",
        execution_deadline: "Rok izvedbe (št. dni)",
        fee: {
          fix: "Fiksni znesek v EUR",
          percent: "% bruto plače"
        },
        fee_lump: "Pavšalni znesek ob podpisu",
        fee_interim: "Vmesni honorar",
        fee_final: "Končni honorar",
        eur: " (EUR)",
        responsible: "Odgovorna oseba Competo",
        user_not_found: "Oseba ne obstaja",
        company_not_found: "Podjetje ne obstaja",
        salary: "Pričakovani honorar",
        installment: "Obroki",
        sales_consultant: "Prodajni svetovalec",
        type: "Področje",
        headhunting: "Headhunting",
        date: "Datum ponudbe",
        validity: "Veljavnost ponudbe"
      },
      contacts: {
        contacts_popup: {
          phone: "Telefonska številka",
          email: "Elektronski naslov",
          save_button: "Shrani",
          type: "Tip",
          label: "Ime in priimek",
          value: "Vrednost",
          contacts: "Kontakti",
          empty_list: "Podjetje nima kontaktov."
        },
        edit_button: "Uredi kontakte",
        select_button: "Izberi kontakte",
        phone: "Telefonska številka",
        email: "Elektronski naslov",
        save_button: "Shrani",
        type: "Tip",
        label: "Ime in priimek",
        value: "Vrednost"
      },
      sidebar: {
        edit_document: "Uredi Word",
        status_select: "Status ponudbe",
        file_select: "Prikazana datoteka",
        buttons: {
          download_word: "Prenesi Word datoteko",
          upload_pdf: "Naloži PDF datoteko",
          download_pdf: "Prenesi PDF datoteko"
        },
        pdf_doesnt_exist: "Naloži PDF",
        prompts: {
          delete_word: {
            title: "Izbris Word datoteke",
            message: "Word datoteka bo izbrisana in je ne bo možno obnoviti. V naslednjem koraku bo omogočeno ponovno generiranje ponudbe. Ali ste prepričani, da želite izbrisati Word datoteko?",
            confirm: "Izbriši",
            cancel: "Prekliči"
          }
        },
        delete_word: "Izbriši Word datoteko",
        notify_error: "Prišlo je do napake."
      },
      preview: {
        generate_offer: "Generiraj ponudbo",
        document_doesnt_exist: "Dokument ne obstaja",
        preparing_document: "Pripravljamo vaš dokument",
        generate_offer_notify: "Dokument je shranjen v aplikaciji"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbris ponudbe {title}",
      message: "Ste prepričani da želite izbrisati ponudbo {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili ponudbo {title}",
    updated: "Uspešno ste posodobili ponudbo {title}",
    deleted: "Ponudba {title} je bila izbrisana",
    not_found: "Ponudba ni bila najdena"
  }
}
